<template>
  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" width="80%" @close="handleClose" :showClose="showclose" v-loading="loading">
    <div style="margin-top: -34px">
      <div class="button">
        <div type="text" v-for="(item, index) in buttons" :key="index" :class="isActive == index ? 'isActive' : ''" @click="changeType(index)">
          {{ item.name }}
        </div>
      </div>
      <div v-show="type == 0">
        <div class="inner">
          <el-form ref="form" :inline="true" :model="form">
            <el-card>
              <div class="head-1-left">
                <div>
                  <img class="pic" :src="result.customPicUrl ? result.customPicUrl : 'https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png'" alt="" />
                </div>
                <div class="inner_top">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="姓名:">{{ result.customName }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="手机号:">{{ result.tel1 }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="预约类型:">{{ result.tasktypeName }}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="机构:">{{ result.companyName }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="产品类型:">{{ result.taskName }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="试剂盒编号:">
                        <el-input v-model="form.sampleboxNo" placeholder="请输入试剂盒编号"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="bottom-1">
                <div class="head-2">
                  <el-row v-if="info.samplingType == 3">
                    <el-form-item label="邮寄地址:">{{ result.samplingAddrGo }}</el-form-item>
                  </el-row>
                  <el-row class="row-row" v-if="info.samplingType == 3">
                    <el-col :span="8">
                      <el-form-item label="邮寄时间:">
                        <el-date-picker class="NBclass" v-model="form.taskStartTime1" type="date" :picker-options="expireTimeOption" @change="changeTaskStartTime1" value-format="yyyy-MM-dd" placeholder="选择日期">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="快递单号:">
                        <el-input v-model="form.expressNo1" @input="onInput()" placeholder="请输入快递单号"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="快递公司:">
                        <el-select v-model="form.expressName1" @change="changeSelect" clearable placeholder="请选择">
                          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-form-item label="取件地址:">
                      <el-input v-model="result.samplingAddrCome" disabled></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row class="row-row">
                    <el-col :span="8">
                      <el-form-item style="display: flex">
                        <span style="margin-right: 10px">取件时间:</span>
                        <div class="time" style="margin-right: -100px">
                          <el-date-picker v-model="form.receiptTime" :disabled="isChange" value-format="yyyy-MM-dd" type="date" @input="changeInput" placeholder="选择日期">
                          </el-date-picker>
                          <el-select v-model="form.value2" @change="getReceiptTime" :disabled="isChange" placeholder="请选择">
                            <el-option label="上午" value="1"> </el-option>
                            <el-option label="下午" value="2"> </el-option>
                          </el-select>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="快递单号:">
                        <el-input v-model="form.expressNo2" :disabled="isChange" @input="onInput()" placeholder="请输入快递单号"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="快递公司:">
                        <el-select v-model="form.expressName2" :disabled="isChange" @change="changeSelect" clearable placeholder="请选择">
                          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="headportrait">
                  <img :src="src" alt="" />
                </div>
              </div>
            </el-card>
            <el-card class="taskType" v-if="form.substatus=='309'">
              <el-row>
                <el-col>
                  <el-form-item label="样品合格判定：" label-width="110px">
                    <el-select v-model="form.eligible" placeholder="请选择" @change="selectEligible">
                      <el-option label="合格" :value="1"></el-option>
                      <el-option label="不合格" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label="备注" v-if="form.eligible==2" label-width="110px">
                    <el-input style="width: 600px" type="textarea" v-model="form.remarks" placeholder="详细说明不合格原因，用户手机端可同步接收，并做好再次采样邮寄的准备。"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="taskType">
              <el-row>
                <el-col>
                  <el-form-item label="任务状态">
                    <el-select v-model="form.substatus" @change="OnChange" placeholder="请选择任务状态">
                      <el-option v-for="(item, index) in list" :key="index" :label="item.name" :value="item.code"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-form>
        </div>
      </div>
      <!-- 历史 -->
      <div v-show="type == 1">
        <el-card class="card-progress">
          <div class="outBox" v-for="(item, index) in result.samplingTaskHistoryVos" :key="index">
            <div class="line"></div>
            <div class="word">
              <div class="outPoint" :style="outerPoint(index)">
                <div class="point" :style="point(index)"></div>
              </div>
              &nbsp;&nbsp; <span>{{ item.time }}</span>&nbsp;&nbsp;
              <span style="margin-right:20px">{{ item.processName }}</span>
              <span v-if="item.processType=='1100'">驳回原因:{{item.remark}}</span>
            </div>
            <div class="line"></div>
          </div>
        </el-card>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequestAnthor } from '@/api'
export default {
  data() {
    return {
      dialogVisible: true,
      type: 0,
      showclose: false,
      loading: true,
      // length: null,
      form: {
        sampleboxNo: '',
        value2: '',
        expressNo1: '',
        expressNo2: '',
        substatus: '',
        mailTime: '',
        remarks: '',
        eligible: 1,
        receiptTime: '',
        expressName1: '',
        expressName2: '',
        taskStartTime1: '',
      },
      buttons: [
        {
          name: '当前任务',
        },
        {
          name: '历史进度',
        },
      ],
      options: [
        {
          label: '顺丰',
          value: 1,
        },
        {
          label: '闪送',
          value: 2,
        },
      ],
      outerPoints: [
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
      ],
      points: [
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
      ],
      result: {},
      list: [
        // {
        //   code: '101',
        //   name: '新单'
        // },
        // {
        //   code: '202',
        //   name: '已提交邮寄预约',
        // },
        // {
        //   code: '301',
        //   name: '已确认预约',
        // },
        // {
        //   code: '302',
        //   name: '客户确认取件',
        // },
        {
          code: '305',
          name: '已快递',
        },
        // {
        //   code: '306',
        //   name: '客户确认收货',
        // },
        // {
        //   code: '307',
        //   name: '预约取件',
        // },
        {
          code: '308',
          name: '取件成功',
        },
        {
          code: '309',
          name: '已收取快递',
        },
        {
          code: '401',
          name: '已采样',
        },
        {
          code: '501',
          name: '已送检',
        },
        {
          code: '601',
          name: '已完成',
        },
      ],
      src: '',
      isActive: '',
      isChange: true,
      oldSubStatus: '',
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() <= Date.now() - 24 * 60 * 60 * 1000
        },
      },
    }
  },
  props: ['info'],
  mounted() {
    if (this.info != '') {
      console.log(this.info)
      postRequestAnthor('/samplingComputer/querySamplingInfo', { id: this.info.id })
        .then(res => {
          console.log(res, '当前点击行详情')
          // debugger
          res[0].samplingTaskHistoryVos = res[0].samplingTaskHistoryVos.reverse()
          res[0].taskStartTime1 = res[0].taskStartTime1
          this.result = res[0]
          let num = res[0].samplingType
          console.log(num)
          // if (num == 3) {
          //   // 邮寄任务
          //   console.log(num)
          //   this.list.splice(2, 1)
          // } else
          if (num == 4) {
            // 上门取样
            // this.list.splice(3, 2)
            this.isChange = false
          }
          this.oldSubStatus = this.result.substatus
          if (this.result.taskStartTime2 != null) {
            if (this.result.taskStartTime2.substring(11, 13) == '09') {
              this.result.receiptTime = this.result.taskStartTime2.substring(0, 10)
              this.result.value2 = '1'
            } else if (this.result.taskStartTime2.substring(11, 13) == '14') {
              this.result.receiptTime = this.result.taskStartTime2.substring(0, 10)
              this.result.value2 = '2'
            }
          } else {
            this.result.value2 = ''
          }
          if (this.result.substatus >= '307') {
            this.isChange = false
          }
          if (this.result.substatus < '306') {
            this.result.substatus = '305'
          }
          if (this.result.substatus > '305' && this.result.substatus < '309') {
            this.result.substatus = '308'
          }
          this.result.expressName1 = this.result.expressName1 ? Number(this.result.expressName1) : ''
          this.result.expressName2 = this.result.expressName2 ? Number(this.result.expressName2) : ''
          this.result.mailTime = this.result.taskStartTime1
          this.result.receiptTime = this.result.taskStartTime2
          this.form = this.result
          this.form.remarks = ''
          this.src = this.result.samplingImage
          this.list.forEach((item, index) => {
            if (item.code == this.result.substatus) {
              this.list = this.list.slice(index + 1)
            }
          })
          this.form.substatus = this.list[0].code
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  },

  methods: {
    selectEligible(value) {
      if (value == 1) {
        this.form.remarks = ''
      }
    },
    handleClose() {
      this.$emit('closeMailTask')
    },
    outerPoint(i) {
      return this.outerPoints[i]
    },
    point(i) {
      return this.points[i]
    },
    changeTaskStartTime1() {
      this.$forceUpdate()
    },
    changeType(val) {
      console.log(val)
      this.type = val
      this.isActive = val
    },
    onInput() {
      this.$forceUpdate()
    },
    OnChange() {
      this.$forceUpdate()
    },
    getMailTime(val) {
      console.log(val)
      if (val == '1') {
        this.form.taskStartTime1 = this.form.mailTime + ' 09:00:00'
        this.form.taskEndTime1 = this.form.mailTime + ' 12:00:00'
      }
      if (val == '2') {
        this.form.taskStartTime1 = this.form.mailTime + ' 14:00:00'
        this.form.taskEndTime1 = this.form.mailTime + ' 17:00:00'
      }
    },
    changeSelect() {
      this.$forceUpdate()
    },
    getReceiptTime(val) {
      console.log(val)
      if (val == '1') {
        this.form.taskStartTime2 = this.form.receiptTime + ' 09:00:00'
        this.form.taskEndTime2 = this.form.receiptTime + ' 12:00:00'
      }
      if (val == '2') {
        this.form.taskStartTime2 = this.form.receiptTime + ' 14:00:00'
        this.form.taskEndTime2 = this.form.receiptTime + ' 17:00:00'
      }
    },
    changeInput(val) {
      this.$forceUpdate()
      console.log(val)
      // this.$set(this.form, 'mailTime', val)
    },
    sureCommit() {
      this.form.samplingType = this.result.samplingType
      this.form.taskEndTime1 = this.form.taskStartTime1
      if (this.form.eligible == 2 && !this.form.remarks) {
        this.$message({
          message: '请填写备注',
          type: 'warning',
        })
        return
      }
      if (this.info.samplingType == 3) {
        if (this.form.sampleboxNo == '' || this.form.expressNo1 == '' || this.form.expressName1 == '') {
          this.$message({
            message: '请完善信息',
            type: 'warning',
          })
        } else if (
          (this.form.sampleboxNo != '') != null &&
          this.form.expressNo1 != '' &&
          this.form.expressName1 != ''
        ) {
          this.form.updateBy = '1'
          this.form.id = this.result.id
          this.form.oldStatus = this.result.status
          this.form.oldSubStatus = this.oldSubStatus
          postRequestAnthor('/samplingComputer/updateMailSampling', this.form).then(res => {
            console.log(res, '修改邮寄任务')
            this.$message({
              message: '修改成功',
              type: 'success',
            })
            this.handleClose()
          })
        }
      } else {
        this.form.updateBy = '1'
        this.form.id = this.result.id
        this.form.oldStatus = this.result.status
        this.form.oldSubStatus = this.oldSubStatus
        postRequestAnthor('/samplingComputer/updateMailSampling', this.form).then(res => {
          console.log(res, '修改邮寄任务')
          this.$message({
            message: '修改成功',
            type: 'success',
          })
          this.handleClose()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.button {
  div {
    padding: 10px 0;
    margin-right: 30px;
    font-size: 16px;
    color: #444444;
  }
  margin-bottom: 20px;
  display: flex;
}
.form-bottom {
  margin-top: 20px;
}
.head-1-left {
  width: 100%;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  :first-child {
    img {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      margin-right: 36px;
    }
  }
  :last-child {
    flex: 1;
  }
}
.headportrait {
  width: 150px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.bottom-1 {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  justify-content: space-between;
  margin-top: 43px;
  .head-2 {
    flex: 1;
    // margin-top: 20px;
    .row {
      display: flex;
    }
    .el-form-item {
      width: 100%;
    }
  }
}
.taskType {
  margin-top: 25px;
}

.isActive {
  border-bottom: 2px solid #5764f1;
  color: #5764f1 !important;
}
.step {
  width: 30%;
  margin: 0 auto;
}
.card-progress {
  min-height: 200px;
}
.outBox {
  width: 30%;
  margin: 0 auto;
}
.word {
  display: flex;
  align-items: center;
  margin: 0 auto;
  .outPoint {
    border-radius: 50%;
    .point {
      margin: 2px;
      width: 4px;
      height: 4px;
      background: #999;
      border-radius: 50%;
    }
  }
}
.line {
  width: 1px;
  height: 20px;
  background: #e6e6e6;
  margin: -5px 4px -5px;
}
.row-row {
  :first-child {
    .el-form-item--small .el-form-item__content {
      width: 80% !important;
      .time {
        display: inline-block;
        width: 80%;
        .el-date-editor {
          width: 48%;
        }
        .el-select {
          width: 32%;
        }
      }
    }
  }
  :nth-child(2) {
    .el-form-item--small .el-form-item__content {
      width: 80% !important;
      // .el-select {
      //   width: 80%;
      // }
    }
  }
}
.inner_top {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #676767;
  .el-form-item--small.el-form-item {
    margin-bottom: 8px;
  }
}
.NBclass {
  width: auto !important;
}
</style>