<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="客户姓名:">
              <el-input v-model="form.customerName" :clearable="true" placeholder="请输入客户名称"></el-input>
            </el-form-item>
            <el-form-item label="客户电话:">
              <el-input v-model="form.customerPhone" :clearable="true" placeholder="请输入客户名称"></el-input>
            </el-form-item>
            <el-form-item label="预约日期:">
              <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="任务状态:">
              <el-select clearable v-model="form.region" placeholder="请选择任务状态">
                <el-option label="-- 请选择 -- " value=""></el-option>
                <el-option v-for="(item, index) in list" :key="index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="getAllMailTask">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <el-button type="danger" plain icon="el-icon-delete" @click="handleDelete">删除</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
      <el-table v-loading="loading" ref="multipleTable" @row-click="handleClickTableRow" :data="tableData" :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#999'}" :cell-style="{'text-align':'center'}" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" fixed width="50"></el-table-column>
        <!-- <el-table-column prop="date" label="序号"> </el-table-column> -->
        <el-table-column prop="customName" label="客户姓名"> </el-table-column>
        <el-table-column prop="subStatusName" label="任务状态"> </el-table-column>
        <el-table-column prop="tel1" label="手机号"> </el-table-column>
        <el-table-column prop="productName" label="产品类型"> </el-table-column>
        <!-- <el-table-column prop="city" label="客服管家"> </el-table-column> -->
        <el-table-column prop="samplingTime" label="完成时间"> </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button @click="addNew(scope.row)" type="text" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background>
    </el-pagination>
    </el-card>
    
    <mailTaskDialog :info="info" @closeMailTask="closeMailTask" v-if="showMailDialog"></mailTaskDialog>
  </div>
</template>

<script>
import mailTaskDialog from '@/views/appointmentManagement/components/mailTaskDialog'
import { formatTime } from '@/utils/utils'
import { postRequestAnthor } from '@/api'

export default {
  data() {
    return {
      form: {
        customerPhone: '',
        customerName: '',
        date1: '',
        region: '',
      },
      tableData: [],
      multipleSelection: [],
      showMailDialog: false,
      loading: true,
      total: null,
      pageNo: 1,
      pageSize:10,
      info: {},
      list: [
        {
          code: '301',
          name: '已确认预约',
        },
        {
          code: '302',
          name: '客户确认取件',
        },
        {
          code: '305',
          name: '已快递'
        },
        {
          code: '306',
          name: '客户确认收货'
        },
        {
          code: '307',
          name: '预约寄件'
        },
        {
          code: '308',
          name: '寄件成功'
        },
        {
          code: '309',
          name: '已收取快递'
        },
        {
          code: '401',
          name: '已采样'
        },
        {
          code: '501',
          name: '已送检'
        },
        {
          code: '601',
          name: '已完成'
        },
      ]
    }
  },
  components: { mailTaskDialog },
  mounted(){
    this.getAllMailTask()
  },
  methods: {
    add() {
      this.showAddReferral = true
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    addNew(val){
      this.info = val
      this.showMailDialog = true
    },
    closeMailTask() {
      this.showMailDialog = false
      this.getAllMailTask()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getAllMailTask()
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.getAllMailTask()
    },
    handleDelete () {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message:'请选择删除项',
          type: 'warning'
        })
      } else {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          let ids = []
          this.multipleSelection.map(item => {
            ids.push(item.id)
          })
          postRequestAnthor('/samplingComputer/deleteSampling', {ids}).then(res=>{
            console.log(res, '删除')
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getAllMailTask()
          })
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          });
        });
      }
    },
    getAllMailTask(){
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        customerName: this.form.customerName ? this.form.customerName : '',
        customerTel: this.form.customerPhone ? this.form.customerPhone : '',
        samplStartTime: this.form.date1 ? formatTime(this.form.date1) : '',
        state: this.form.region ? this.form.region : '',
        samplingType: [3,4],
        subStatus: this.form.region ? this.form.region : '',
        status: [3, 4, 5, 6],
      }
      postRequestAnthor('/samplingComputer/querySamplingInfoPageList', data).then(res=>{
        this.loading = false
        this.total = res.count
        this.tableData = res.data
      }).catch(()=>{
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
// .searchFrom > .el-form-item {
//   margin-right: 4px !important;
// }
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>
